/*------------------------------------*
 * #COLORS */
/*------------------------------------ */

$main-color: #D1242D;
$main-color--darker: #333;
$main-color--lighter: #999;

$secondary-color: #999;

$text-color: #fff;
$secondary-text-color: #000;

$btn-text: #ffffff;
$gray: #333;
$yellow: blue;

/*------------------------------------*
 * #BUTTONS */
/*------------------------------------ */

.btn-main-color {
  background-color: $main-color;

  &:hover {
    background-color: $main-color--lighter;
  }
}

.btn-secondary-color {
  color: #666 !important;
  background: $secondary-color;
}
